import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import Dropzone from '../dropzone/Dropzone';
import { useRecoilState } from 'recoil';
import { imagesAtom } from '@src/domain/atoms/imagesAtom';
import AreYouSureModal from '../modal/AreYouSureModal';

const EditAsset = ({
  name,
  asset,
  assetID,
  collectionID,
  maxFile
}: {
  name: string;
  asset: string;
  assetID: number;
  collectionID: number;
  maxFile: number;
}) => {
  const isItVideo = name?.toLowerCase().includes('video');
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [previewImageURL, setPreviewImageURL] = useState('');
  const [imageAtom, setImageAtom] = useRecoilState<any>(imagesAtom);

  return (
    <Box
      sx={{
        mb: '3rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '3rem',
          alignItems: 'center',
          mb: '1rem'
        }}
        onClick={() => {
          setOpenEdit(prevState => !prevState);
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: '2rem'
          }}
        >
          {name}
        </Typography>
        <Button>Izmeni</Button>
      </Box>
      {openEdit && (
        <Box>
          <Dropzone
            page="product"
            previewImageURL={asset}
            setPreviewImageURL={setPreviewImageURL}
            maxFile={maxFile}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '3rem' }}>
            <Button
              onClick={() => {
                setOpenEdit(false);
                setImageAtom([]);
              }}
            >
              Otkazi
            </Button>
            <Button onClick={() => setOpenModal(true)}>Sacuvaj</Button>
          </Box>
        </Box>
      )}
      <AreYouSureModal
        handleClose={() => {
          setOpenModal(false);
          setImageAtom([]);
        }}
        open={openModal}
        handleConfirm={() => console.log('sas')}
        assetID={assetID}
        collectionID={collectionID}
        maxFile={maxFile}
      />
      {isItVideo && !openEdit && asset && (
        <video width="auto" height="400px" controls style={{ maxWidth: '88vw' }}>
          <source src={asset} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {!isItVideo && !openEdit && asset && (
        <img src={asset} style={{ height: 200, maxWidth: '88vw' }} />
      )}
    </Box>
  );
};

export default EditAsset;
