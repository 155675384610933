import { Box, Typography } from '@mui/material';
import axiosInstance from '@src/infrastructure/services/config';
import EditAsset from '@src/ui/components/shared/editAsset/EditAsset';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();
  const { data: singleCollectionData } = useQuery({
    queryFn: () => {
      return axiosInstance.get(`/collection`);
    },
    queryKey: ['allColections']
  });

  const { data: homePageVideoData } = useQuery({
    queryFn: () => {
      return axiosInstance.get(`/collection/2`);
    },
    queryKey: ['homePageVideo']
  });

  const { data: contactVideo } = useQuery({
    queryFn: () => {
      return axiosInstance.get(`/collection/4`);
    },
    queryKey: ['contactVideo']
  });

  const { data: footerImage } = useQuery({
    queryFn: () => {
      return axiosInstance.get(`/collection/5`);
    },
    queryKey: ['footerImage']
  });

  const { data: brandDNABackground } = useQuery({
    queryFn: () => {
      return axiosInstance.get(`/collection/6`);
    },
    queryKey: ['brandDNABackground']
  });

  // const { data: homeDiscoverImages } = useQuery({
  //   queryFn: () => {
  //     return axiosInstance.get(`/collection/7`);
  //   },
  //   queryKey: ['homeDiscoverImages']
  // });

  return (
    <Box sx={{
      mt: '1rem',
      mb: '5rem'
    }}>
      <Typography variant="h1" sx={{
        fontSize: '4rem',
        mb: '4rem'
      }}>Assets on web site</Typography>
      <EditAsset
        name={homePageVideoData?.data.data.Name}
        asset={homePageVideoData?.data.data.Media[0]?.URL}
        assetID={homePageVideoData?.data.data.Media[0]?.ID}
        collectionID={homePageVideoData?.data.data.ID}
        maxFile={1}
      />
      <EditAsset
        name={contactVideo?.data.data.Name}
        asset={contactVideo?.data.data.Media[0]?.URL}
        assetID={contactVideo?.data.data.Media[0]?.ID}
        collectionID={contactVideo?.data.data.ID}
        maxFile={1}
      />
      <EditAsset
        name={footerImage?.data.data.Name}
        asset={footerImage?.data.data.Media[0]?.URL}
        assetID={footerImage?.data.data.Media[0]?.ID}
        collectionID={footerImage?.data.data.ID}
        maxFile={1}
      />
      <EditAsset
        name={brandDNABackground?.data.data.Name}
        asset={brandDNABackground?.data.data.Media[0]?.URL}
        assetID={brandDNABackground?.data.data.Media[0]?.ID}
        collectionID={brandDNABackground?.data.data.ID}
        maxFile={1}
      />
      {/* <EditAsset
        name={homeDiscoverImages?.data.data.Name}
        asset={homeDiscoverImages?.data.data.Media[0].URL}
        assetID={homeDiscoverImages?.data.data.Media[0].ID}
        collectionID={homeDiscoverImages?.data.data.ID}
        maxFile={2}
      /> */}
    </Box>
  );
};

export default HomePage;
