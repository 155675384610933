import { Box, Icon, Switch, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ReorderLayout from './ReorderLayout';

const DetailsLayout = (id: any, page: string) => {
  const { data, isFetched, refetch } = useQuery({
    queryKey: [id.id],
    queryFn: () => axiosInstance.get(`/${id.page}/${id.id}`),
    select: (data: any) => data.data.data
  });

  const { data: optionData } = useQuery({
    queryKey: ['options'],
    queryFn: () => {
      return axiosInstance.get(`/option`);
    },
    select: (data: any) => data.data.data.Data
  });

  const { t } = useTranslation();

  const handleProductAvailability = (changedAvailability: boolean, ID: number) => {
    // when changing product availability if there are no other available options toggle route is called
    let optionsAvailability: { available: boolean; ID: number }[] = [];
    let booleansAvailability: boolean[] = [];
    data?.Options?.forEach((option: any) => {
      optionsAvailability.push({ available: option.Available, ID: option.OptionID });
    });
    optionsAvailability.forEach((option: { available: boolean; ID: number }) => {
      if (option.ID === ID) {
        option.available = changedAvailability;
      }
      booleansAvailability.push(option.available);
    });
    return booleansAvailability.includes(true) !== data?.Available;
  };

  return (
    <Box>
      {isFetched && id.page === 'collection' ? (
        <Box>
          <Typography>
            {t('name')}: {data.Name}
          </Typography>
          <Typography>
            {t('subname')}: {data.Subname}
          </Typography>
          <Typography>
            {t('description')}: {data.Description}
          </Typography>
          <Box sx={{ display: 'flex', gap: '4px', marginTop: '2rem', maxWidth: '80vw' }}>
            {data?.Media?.map((media: any) => {
              return (
                <Box key={media.ID}>
                  <img src={media.URL} alt={media.AltText} height={300} width="auto" />
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : isFetched && id.page === 'category' ? (
        <Box>
          <Typography>
            {t('name')}: {data.Name}
          </Typography>
          <Typography>
            {t('order')}: {data.Order}
          </Typography>
          <ReorderLayout elements={data?.Products} categoryID={data?.ID} />
        </Box>
      ) : isFetched && id.page === 'contact' ? (
        <Box>
          <Typography>
            {t('name')}: {data.Name}
          </Typography>
          <Typography>
            {t('email')}: {data.Email}
          </Typography>
          <Typography>
            {t('message')}: {data.Message}
          </Typography>
        </Box>
      ) : isFetched && id.page === 'country' ? (
        <Box>
          <Typography>
            {t('name')}: {data.Name}
          </Typography>
          <Typography>
            {t('internationalCode')}: {data.InternationalCode}
          </Typography>
          <Typography>
            {t('order')}: {data.Order}
          </Typography>
        </Box>
      ) : isFetched && id.page === 'option' ? (
        <Box>
          <Typography>
            {t('optionName')}: {data.Name}
          </Typography>
          <Typography>
            {t('optionType')}: {data.Type}
          </Typography>
        </Box>
      ) : isFetched && id.page === 'order' ? (
        <Box>
          <Typography>
            {t('customerName')}: {data?.Metadata?.Firstname} {data?.Metadata?.Lastname}
          </Typography>
          <Typography>
            {t('paymentType')}: {data?.PaymentType}
          </Typography>
          <Typography>
            {t('orderID')}: {data?.ID}
          </Typography>
          <Typography>
            {t('note')}: {data?.Note}
          </Typography>
          <Typography>
            {t('shippingAddress')}: {data?.ShippingAddress}, {data?.Metadata?.PostCode}{' '}
            {data?.Metadata?.City}
          </Typography>
          <Typography>
            {t('shippingType')}: {data?.ShippingType}
          </Typography>
          <Typography>
            {t('subtotal')}: {data?.Subtotal}
          </Typography>
          <Typography>
            {t('total')}: {data?.Total}
          </Typography>
          <Typography>
            {t('phone')}: {data?.Metadata?.Phone}
          </Typography>
          <Typography>
            {t('email')}: {data?.Metadata?.Email}
          </Typography>
          <Typography>
            {t('orderStatus')}: {data?.Status}
          </Typography>
          <Typography>
            <Box>
              {t('orderLines')}:
              {data?.OrderLines?.map((orderline: any) => {
                const found = optionData?.find(
                  (option: any) => option.ID === orderline?.Metadata?.Options?.OptionID
                );
                return (
                  <Box key={orderline?.ID + orderline?.OrderID + orderline?.ProductID}>
                    <Typography>
                      {orderline?.Quantity}x {orderline?.Product?.Name} - {found?.Name}
                    </Typography>
                  </Box>
                );
              })}
              <Typography></Typography>
            </Box>
          </Typography>
        </Box>
      ) : isFetched && id.page === 'press' ? (
        <Box>
          <Typography>
            {t('name')}: {data.Name}
          </Typography>
          <Typography>
            {t('type')}: {data.Type}
          </Typography>
          <Typography>
            {t('date')}: {data.Date}
          </Typography>
          <Box sx={{ display: 'flex', gap: '4px', marginTop: '2rem', maxWidth: '80vw' }}>
            {data?.Media?.map((media: any) => {
              return (
                <Box key={media.ID}>
                  <img src={media.URL} alt={media.AltText} height={300} width="auto" />
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : isFetched && id.page === 'product' ? (
        <Box>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box>
              <Typography>
                {t('name')}: {data?.Name}
              </Typography>
              <Typography>
                {t('subname')}: {data?.Subname}
              </Typography>
              <Typography>
                {t('category')}:{' '}
                {data?.Categories?.map((category: any) => {
                  return category.Category.Name ?? '';
                })}
              </Typography>
              <Typography sx={{ display: 'inline' }}>
                {t('composition')}:{' '}
                {
                  <div
                    className="inline-display"
                    dangerouslySetInnerHTML={{
                      __html: data.Composition.replaceAll('/n', '<br>')
                    }}
                  />
                }
              </Typography>
              <Typography>
                {t('description')}:{' '}
                {
                  <div
                    className="inline-display"
                    dangerouslySetInnerHTML={{
                      __html: data.Description.replaceAll('/n', '<br>')
                    }}
                  />
                }
              </Typography>
              {/* <Typography>
                {t('sku')}: {data.Sku !== '' ? data.Sku : 'N/A'}
              </Typography> */}
              <Typography>
                {t('discountPercentage')}: {data.Discount}%
              </Typography>
              <Typography>
                {t('discountPrice')}: {data.DiscountPrice}
              </Typography>
              <Typography>
                {t('originalPrice')}: {data.OriginalPrice}
              </Typography>
              <Typography>
                {t('featuredQuestion')}: {data.Featured ? t('yesFeatured') : t('noFeatured')}
              </Typography>
            </Box>
            <Box>
              {data?.Options?.map((option: any) => {
                return (
                  <Box key={option.ID} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography
                      sx={[
                        { backgroundColor: 'red', padding: '2px', color: 'white' },
                        option.Available && { backgroundColor: 'green' }
                      ]}
                    >
                      {option.Option.Type.toUpperCase()} {option.Option.Name}{' '}
                      {option.Available ? t('currentlyAvailable') : t('currentlyUnavailable')}
                    </Typography>
                    <Switch
                      checked={option.Available}
                      onChange={() => {
                        axiosInstance.put(`/product/toggle-option/${id.id}/${option.OptionID}`);
                        const available = handleProductAvailability(
                          !option.Available,
                          option.OptionID
                        );
                        if (available) {
                          axiosInstance.put(`/product/toggle-availability/${id.id}`);
                        }
                        setTimeout(() => refetch(), 1000);
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            {data?.Options.length === 0 && (
              <Switch
                onChange={() => {
                  axiosInstance.put(`/product/toggle-availability/${id.id}`);
                  setTimeout(() => refetch(), 1000);
                }}
                checked={data.Available}
              />
            )}
            {data.Options.length ? '' : data.Available ? 'Available' : 'Unavailable'}
          </Box>
          <Box sx={{ display: 'flex', gap: '4px', marginTop: '2rem' }}>
            {data?.Media?.map((media: any) => {
              return (
                <Box
                  key={media.ID}
                  sx={[
                    media.URL === data.PreviewImage && {
                      borderColor: 'green',
                      borderWidth: '2px',
                      borderStyle: 'solid'
                    },
                    {
                      position: 'relative'
                    }
                  ]}
                >
                  <img src={media.URL} alt={media.AltText} height={300} width="auto" />
                  {media.URL === data.PreviewImage && (
                    <Icon
                      color="success"
                      sx={{ position: 'absolute', bottom: '5px', right: '5px' }}
                    >
                      <CheckCircleOutlineOutlinedIcon color="success" />
                    </Icon>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : isFetched && id.page === 'transaction' ? (
        <Box>
          <Typography>
            {t('orderID')}: {data.OrderID}
          </Typography>
          <Typography>Status: {data.Status}</Typography>
          <Typography>
            {t('paymentAmount')}: {data.PaymentAmount}
          </Typography>
          <Typography>
            {t('paymentMethod')}: {data.PaymentMethod}
          </Typography>
          <Typography>
            {t('reference')}: {data.Reference}
          </Typography>
        </Box>
      ) : isFetched && id.page === 'user' ? (
        <Box>
          <Typography>
            {t('name')}: {data.Firstname} {data.Lastname}
          </Typography>
          <Typography>
            {t('email')}: {data.Email}
          </Typography>
          <Typography>
            {t('address')}: {data?.Address}
          </Typography>
          <Typography>
            {t('city')}: {data?.City}
          </Typography>
          <Typography>
            {t('postcode')}: {data?.Postcode}
          </Typography>
          <Typography>
            {t('phone')}: {data?.Phone}
          </Typography>
        </Box>
      ) : isFetched && id.page === 'custom-request' ? (
        <Box>
          <Typography>
            {t('name')}: {data.Name}
          </Typography>
          <Typography>
            {t('email')}: {data.Email}
          </Typography>
          <Typography>
            {t('phone')}: {data.Phone}
          </Typography>
          <Typography>
            {t('message')}: {data.Message}
          </Typography>
          <Typography>Status: {data.Status}</Typography>
        </Box>
      ) : (
        'undefined'
      )}
    </Box>
  );
};

export default DetailsLayout;
