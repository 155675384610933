import React from 'react';
import ReactDOM from 'react-dom/client';
//hooks
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import '../i18n';

// Sentry.init({
//   dsn: 'https://63d948bc29504489a155d4e391ebe8e8@o4504843936858112.ingest.sentry.io/4504844180193280',
//   integrations: [new Sentry.browserTracingIntegration()],
//   tracesSampleRate: 1.0,
//   release: 'release_0.0.2'
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
          <App />
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);
