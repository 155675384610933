import { useMemo, useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Sortable = ({
  id,
  index,
  name,
  img,
  groupID,
  setReorderItems,
  sku
}: {
  id: any;
  index: any;
  name: string;
  img: string;
  groupID: number;
  setReorderItems: any;
  sku: any;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    innerHeight: 500,
    overflow: 'hidden',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <p>{name}</p>
      <img src={img} className="reorder-img" />
    </div>
  );
};

export default Sortable;
