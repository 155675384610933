import { Product } from '@src/domain/interfaces/product';
import React, { useEffect, useMemo, useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import Sortable from '../components/shared/sortable/Sortable';
import { Button } from '@mui/material';
import useUpdate from '../hooks/useUpdate';
import useFetch from '../hooks/useFetch';
// import useUpdateProductOrderValue from '../hooks/useUpdateProductOrderValue';
import axios from 'axios';
import axiosInstance from '@src/infrastructure/services/config';
import useUpdateProductOrderValue from '../hooks/useUpdateProductOrderValue';

const ReorderLayout = ({ elements, categoryID }: { elements: any[]; categoryID: number }) => {
  const [items, setItems] = useState(elements);
  //   useUpdateProductOrderValue()
  useEffect(() => {
    let newArray: any = [];
    elements.forEach(element => {
      newArray.push(element.Product);
    });

    elements.sort(function (a: any, b: any) {
      return parseInt(a.Product.Sku) - parseInt(b.Product.Sku);
    });

    setItems(newArray);
  }, []);

  const itemIds = useMemo(() => items?.map(item => item.ID), [items]);

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.findIndex(item => item.ID === active.id);
        const newIndex = items.findIndex(item => item.ID === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <div>
      ReorderLayout
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={itemIds}>
          <div className="reorder-wrapper">
            {items?.map((element: any, index: any) => {
              return (
                <Sortable
                  key={element.ID}
                  id={element.ID}
                  index={index}
                  name={element.Name}
                  img={element.PreviewImage}
                  groupID={categoryID}
                  setReorderItems={setItems}
                  sku={element.Sku}
                />
              );
            })}
          </div>
        </SortableContext>
      </DndContext>
      <div className="reorder-btns-wrapper">
        <Button
          onClick={e => {
            setItems(elements);
            window.location.reload();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={e => {
            setTimeout(() => {
              items.forEach(async (item, index) => {
                await axiosInstance.patch(`/product/${item.ID}`, {
                  Sku: index.toString()
                });
              });
            }, 400);

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ReorderLayout;
