import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo, useRef, useState } from 'react';
import { Product } from '@src/domain/interfaces/product';
import PageLayout from '@src/ui/layouts/PageLayout';
import { Crud } from '@src/domain/interfaces/crud';
import {
  Box,
  Checkbox,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextareaAutosize,
  Typography,
  FormHelperText
} from '@mui/material';
import axiosInstance from '@src/infrastructure/services/config';
import { Category } from '@src/domain/interfaces/category';
import { Option } from '@src/domain/interfaces/option';
import { useQuery } from '@tanstack/react-query';
import Dropzone from '@src/ui/components/shared/dropzone/Dropzone';
import { number, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { productErrorAtom } from '@src/domain/atoms/errorAtoms/ProductErrorAtom';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const ProductsPage: React.FC = (): JSX.Element => {
  const [selectedCategories, setSelectedCategories] = useState<Category[] | []>([]);
  const [productsErrors, setProductsErrors] = useRecoilState(productErrorAtom);
  // const { refetch } = useFetch('product');
  const { t } = useTranslation();
  const [productID, setProductID] = useState<number | undefined>();
  const [featured, setFeatured] = useState<boolean>(false);
  //previously uploaded images
  const [productShownImages, setProductShownImages] = useState<any>();
  const [previewImageURL, setPreviewImageURL] = useState<string>('');
  //state that controls if product is available in different options
  const [productDiffOptions, setProductDiffOptions] = useState<boolean>(false);
  //state that controls if product is available and what options of product are available
  const [productAvailable, setProductAvailable] = useState<boolean>(true);
  const [productCheckedAvailable, setProductCheckedAvailable] = useState<number[]>([]);
  const [productCheckedCurrently, setProductCheckedCurrently] = useState<number[]>([]);
  const [productInDrafts, setProductInDrafts] = useState<boolean>(false);

  const descriptionRef = useRef<any>();
  const compositionRef = useRef<any>();

  const previewImageIndex = localStorage.getItem('imageIndex');
  previewImageIndex ? JSON.parse(previewImageIndex) : 0;

  const handleAvailabilityChange = (id: number) => {
    axiosInstance.put(`/product/toggle-availability/${id}`);
    // setTimeout(() => refetch(), 1000);
  };

  const handleSelectCategory = (event: any) => {
    const value = event.target.value!;
    setSelectedCategories(value);
  };

  //when user clicks on a particular item, that item is fetched by id and data is shown in editing modal
  const { data: singleProductData } = useQuery({
    queryKey: ['singleProduct', productID],
    queryFn: () => {
      return axiosInstance.get(`/product/${productID}`);
      setProductCheckedAvailable([]);
      setProductCheckedCurrently([]);
      setProductInDrafts(false);
      setProductShownImages([]);
      //every time user opens another product editing modal, images pending for removal are reset
      localStorage.removeItem('removedImages');
    },
    select: (data: any) => data?.data.data,
    onSuccess: data => {
      setFeatured(data.Featured);
      let categories: any = [];
      data.Categories.forEach((category: any) => {
        categories.push(category.Category.ID);
      });
      setSelectedCategories(categories);
      setProductShownImages(data?.Media);
      let availableOptions: any = [];
      let checkedOptions: any = [];
      data.Options.forEach((option: any) => {
        availableOptions.push(option.OptionID);
        option.Available && checkedOptions.push(option.OptionID);
      });
      setProductCheckedAvailable(availableOptions);
      setProductCheckedCurrently(checkedOptions);
      setProductInDrafts(data?.Draft);
      if (availableOptions.length !== 0) {
        setProductDiffOptions(true);
      }
      setPreviewImageURL(data?.PreviewImage);
      compositionRef.current.value = data?.Composition;
      descriptionRef.current.value = data?.Description;
    },
    enabled: productID !== undefined
  });

  const { data: categoryData } = useQuery({
    queryKey: ['product-categories'],
    queryFn: () => axiosInstance('/category')
  });
  const { data: optionData } = useQuery({
    queryKey: ['product-options'],
    queryFn: () => axiosInstance('/option')
  });

  const handleRemoveImage = (file: any) => {
    const removedImages: string | null = localStorage.getItem('removedImages');
    if (removedImages !== null) {
      const parsedImages = JSON.parse(removedImages);
      // const newRemovedImages = JSON.parse(removedImages);
      if (!parsedImages.includes(file.ID)) {
        localStorage.setItem('removedImages', JSON.stringify([...parsedImages, file.ID]));
      }
    } else {
      localStorage.setItem('removedImages', JSON.stringify([file.ID]));
    }
  };

  const nameSchema = string().required();
  const descriptionSchema = string();
  const compositionSchema = string().required();
  const vatSchema = number().required();
  const discountSchema = number().required().max(100).min(0);
  const discountPriceSchema = number();
  const originalPriceSchema = number().required();

  const productsColumns = useMemo<MRT_ColumnDef<Product>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: t('productName'),
        muiEditTextFieldProps: {
          required: true,
          error: !!productsErrors.Name,
          helperText: productsErrors.Name,
          onFocus: () => {
            setProductsErrors({ ...productsErrors, Name: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema.validate(fieldValue).catch(() => {
              setProductsErrors({ ...productsErrors, Name: t('nameRequiredMsg') });
            });
          }
        }
      },
      {
        accessorKey: 'Subname',
        header: t('productSubname')
      },
      {
        accessorKey: 'Description',
        header: t('productDescription'),
        Edit: ({ row }) => {
          //description are custom textarea elements because they can be shown in multiple rows
          setProductID(row.original.ID);
          return (
            <Box>
              <Typography>{t('description')}</Typography>
              <TextareaAutosize
                ref={descriptionRef}
                onChange={e => {
                  descriptionRef.current.value = e.target.value;
                  row._valuesCache.Description = descriptionRef?.current?.value;
                }}
                className="textarea"
              ></TextareaAutosize>
            </Box>
          );
        },
        muiEditTextFieldProps: {
          error: !!productsErrors.Description,
          helperText: productsErrors.Description,
          onFocus: () => {
            setProductsErrors({ ...productsErrors, Description: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            descriptionSchema.validate(fieldValue).catch(() => {
              setProductsErrors({ ...productsErrors, Description: t('descriptionRequiredMsg') });
            });
          }
        }
      },
      {
        accessorKey: 'Composition',
        header: t('composition'),
        //Composition are custom textarea elements because they can be shown in multiple rows
        Cell: ({ row }) => {
          return (
            <Box>
              {row.original.Composition && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: row.original.Composition.replaceAll('/n', '<br>')
                  }}
                />
              )}
            </Box>
          );
        },
        Edit: ({ row }) => {
          return (
            <Box>
              <Typography>{t('composition')}</Typography>
              <TextareaAutosize
                ref={compositionRef}
                onChange={e => {
                  compositionRef.current.value = e.target.value;
                  row._valuesCache.Composition = compositionRef?.current?.value;
                }}
                className="textarea"
              ></TextareaAutosize>
            </Box>
          );
        },
        muiEditTextFieldProps: {
          required: true,
          error: !!productsErrors.Composition,
          helperText: productsErrors.Composition,
          onFocus: () => {
            setProductsErrors({ ...productsErrors, Composition: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            compositionSchema.validate(fieldValue).catch(() => {
              setProductsErrors({
                ...productsErrors,
                Composition: t('compositionRequiredMsg')
              });
            });
          }
        }
      },
      {
        accessorKey: 'Collection',
        header: 'Collection'
      },
      {
        accessorKey: 'Vat',
        header: t('vat'),
        muiEditTextFieldProps: {
          required: true,
          error: !!productsErrors.Vat,
          helperText: productsErrors.Vat,
          onFocus: () => {
            setProductsErrors({ ...productsErrors, Vat: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            vatSchema.validate(fieldValue).catch(() => {
              setProductsErrors({
                ...productsErrors,
                Vat: t('vatRequiredMsg')
              });
            });
          }
        }
      },
      {
        accessorKey: 'Discount',
        header: t('discountPercentage'),
        muiEditTextFieldProps: {
          required: true,
          error: !!productsErrors.Discount,
          helperText: productsErrors.Discount,
          onFocus: () => {
            setProductsErrors({ ...productsErrors, Discount: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            discountSchema.validate(fieldValue).catch(() => {
              setProductsErrors({
                ...productsErrors,
                Discount: t('discountRequiredMsg')
              });
            });
          }
        }
      },
      {
        accessorKey: 'DiscountPrice',
        header: t('discountPrice'),
        muiEditTextFieldProps: {
          error: !!productsErrors.DiscountPrice,
          helperText: productsErrors.DiscountPrice,
          onFocus: () => {
            setProductsErrors({ ...productsErrors, DiscountPrice: '' });
          }
        }
      },
      {
        accessorKey: 'OriginalPrice',
        header: t('originalPrice'),
        muiEditTextFieldProps: {
          required: true,
          error: !!productsErrors.OriginalPrice,
          helperText: productsErrors.OriginalPrice,
          onFocus: () => {
            setProductsErrors({ ...productsErrors, OriginalPrice: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            originalPriceSchema.validate(fieldValue).catch(() => {
              setProductsErrors({
                ...productsErrors,
                OriginalPrice: t('originalpriceRequiredMsg')
              });
            });
          }
        }
      },
      {
        accessorKey: 'Draft',
        header: t('productStatus'),
        Cell: ({ row }) => {
          return <Box>{row.original.Draft ? 'In drafts' : 'Published'}</Box>;
        },
        Edit: ({ row }) => {
          row._valuesCache.Featured = featured;
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Checkbox
                checked={productInDrafts}
                onChange={() => {
                  setProductInDrafts(prevState => {
                    return !prevState;
                  });
                  row._valuesCache.Draft = productInDrafts;
                }}
              />
              <Typography>{t('draftProducts')}</Typography>
            </Box>
          );
        }
      },
      {
        accessorKey: 'Featured',
        header: t('featured'),
        Cell: ({ row }) => {
          return (
            <Box>
              <Checkbox disabled checked={row.original.Featured} />
              {t('isProductFeatured')}
            </Box>
          );
        },
        Edit: ({ row }) => {
          row._valuesCache.Featured = featured;
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Checkbox
                checked={featured}
                onChange={() => {
                  setFeatured(prevState => {
                    return !prevState;
                  });
                  row._valuesCache.Featured = featured;
                }}
              />
              <Typography>{t('isProductFeatured')}</Typography>
            </Box>
          );
        }
      },
      {
        accessorKey: 'Categories',
        header: t('productCategories'),
        muiEditTextFieldProps: { required: true },
        Edit: () => {
          //if categories that have children should be shown just delete this
          let parentIDArray: number[] = [];
          categoryData?.data.data.Data?.forEach!((category: Category) => {
            if (category.ParentID !== 0 && !parentIDArray.includes(category.ParentID)) {
              parentIDArray.push(category.ParentID);
            }
          });
          return (
            <Box>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="product-categories-label">{t('productCategories')}</InputLabel>
                <Select
                  labelId="product-categories-label"
                  id="product-categories"
                  multiple
                  value={selectedCategories}
                  onChange={handleSelectCategory}
                  input={<OutlinedInput label="Product-category" />}
                >
                  {categoryData?.data.data.Data.map((category: any) => (
                    <MenuItem key={category.ID} value={category.ID}>
                      {category.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          );
        }
      },
      {
        accessorKey: 'Options',
        header: t('options'),
        muiEditTextFieldProps: { required: true },
        Edit: ({ row }) => {
          //handlers for checking available and currently available product option
          const handleCheckedAvailable = (id: number) => {
            productCheckedAvailable.includes(id)
              ? setProductCheckedAvailable((prevState: number[]) =>
                  prevState.filter((current: number) => current !== id)
                )
              : setProductCheckedAvailable((prevState: number[]) => [...prevState, id]);
          };

          const handleCheckedCurrent = (id: number) => {
            productCheckedCurrently.includes(id)
              ? setProductCheckedCurrently((prevState: number[]) =>
                  prevState.filter((current: number) => current !== id)
                )
              : setProductCheckedCurrently((prevState: number[]) => [...prevState, id]);
          };
          return (
            <Box>
              <Typography>{t('productInDifferentOptions')}?</Typography>
              <Switch
                checked={productDiffOptions}
                onChange={() => {
                  setProductDiffOptions(prevState => {
                    return !prevState;
                  });
                }}
              />
              {productDiffOptions && (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography>{t('optionName')}</Typography>
                  <Typography>{t('checkProductOption')}</Typography>
                  <Typography>{t('checkCurrentOption')}</Typography>
                </Box>
              )}
              {productDiffOptions &&
                optionData?.data?.data?.Data?.map((option: Option) => {
                  return (
                    <Box
                      key={option.ID}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexBasis: 'max-content'
                      }}
                    >
                      <Typography width={30}>{option.Name}</Typography>
                      <Checkbox
                        sx={{ flexGrow: 1 }}
                        disableRipple
                        checked={productCheckedAvailable.some(
                          (element: number) => element === option.ID
                        )}
                        onChange={() => handleCheckedAvailable(option.ID)}
                      />
                      <Checkbox
                        sx={{ flexGrow: 1 }}
                        disableRipple
                        disabled={!productCheckedAvailable.includes(option.ID)}
                        checked={productCheckedCurrently.some(
                          (element: number) => element === option.ID
                        )}
                        onChange={() => handleCheckedCurrent(option.ID)}
                      />
                    </Box>
                  );
                })}
            </Box>
          );
        }
      },
      {
        accessorKey: 'Available',
        header: t('available'),
        Edit: ({ row }) => {
          return (
            <Box>
              {!productDiffOptions && (
                <Box>
                  <Typography>{t('productCurrentlyAvailable')}?</Typography>
                  <Switch
                    onChange={() => {
                      setProductAvailable(prevState => {
                        return !prevState;
                      });
                      row._valuesCache.Available = productAvailable;
                    }}
                    checked={productAvailable}
                  />
                </Box>
              )}
            </Box>
          );
        },
        Cell: ({ row }) => {
          return (
            <Box>
              <Switch
                onChange={() => handleAvailabilityChange(row.original.ID!)}
                checked={row.original.Available}
              />
              {row.original.Available ? t('available') : t('unavailable')}
            </Box>
          );
        }
      },
      {
        accessorKey: 'Images',
        header: t('productImage'),
        Edit: () => {
          return (
            <Box>
              <Typography>{t('images')}</Typography>
              <FormHelperText>{t('greenBorder')}</FormHelperText>
              {/* <FormHelperText>{t('noPreviewImage')}</FormHelperText> */}
              <Box sx={{ marginBottom: '1rem' }}>
                {productShownImages?.map((file: any) => {
                  return (
                    <Box key={file.ID} sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box
                        onClick={() => setPreviewImageURL(file.URL)}
                        sx={[
                          file.URL === previewImageURL && {
                            borderColor: 'green',
                            borderWidth: '2px',
                            borderStyle: 'solid'
                          },
                          {
                            position: 'relative'
                          }
                        ]}
                      >
                        <img
                          src={file.URL}
                          height={100}
                          alt={file.AltText}
                          // onLoad={() => {
                          //   //   URL.revokeObjectURL(file.preview);
                          // }}
                        />
                        {file.URL === previewImageURL && (
                          <Icon
                            color="success"
                            sx={{ position: 'absolute', bottom: '5px', right: '5px' }}
                          >
                            <CheckCircleOutlineOutlinedIcon color="success" />
                          </Icon>
                        )}
                      </Box>
                      <IconButton
                        disableRipple
                        onClick={() => {
                          setProductShownImages((prevImages: any) =>
                            prevImages.filter((image: any) => image !== file)
                          );
                          handleRemoveImage(file);
                        }}
                      >
                        <DeleteIcon sx={{ color: 'red' }}></DeleteIcon>
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
              <Dropzone
                page="product"
                previewImageURL={previewImageURL}
                setPreviewImageURL={setPreviewImageURL}
              />
            </Box>
          );
        }
      }
    ],
    [
      optionData,
      categoryData,
      selectedCategories,
      productsErrors,
      t,
      featured,
      productCheckedAvailable,
      productCheckedCurrently,
      singleProductData,
      productShownImages,
      previewImageURL,
      productDiffOptions,
      productAvailable,
      descriptionRef,
      compositionRef,
      productAvailable,
      productInDrafts
    ]
  );

  const crud: Crud = { canCreate: true, canDelete: true, canEdit: true };

  return (
    <PageLayout
      title="Products"
      columns={productsColumns}
      page="product"
      crud={crud}
      selectedCategories={selectedCategories}
      productCheckedAvailable={productCheckedAvailable}
      productCheckedCurrently={productCheckedCurrently}
      productPreviewImage={previewImageURL}
      productAvailable={productAvailable}
      setProductAvailable={setProductAvailable}
      setProductCheckedAvailable={setProductCheckedAvailable}
      setProductCheckedCurrently={setProductCheckedCurrently}
      setProductShownImages={setProductShownImages}
      setPreviewImageURL={setPreviewImageURL}
      setFeatured={setFeatured}
      setProductDiffOptions={setProductDiffOptions}
      setSelectedCategories={setSelectedCategories}
      productInDrafts={productInDrafts}
      setProductInDrafts={setProductInDrafts}
    />
  );
};

export default ProductsPage;
