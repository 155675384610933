import { Box, Button, Modal, Typography } from '@mui/material';
import { imagesAtom } from '@src/domain/atoms/imagesAtom';
import useDeleteMedia from '@src/ui/hooks/media/useDeleteMedia';
import useUploadMedia from '@src/ui/hooks/media/useUploadMedia';
import React from 'react';
import { useRecoilValue } from 'recoil';

const AreYouSureModal = ({
  open,
  handleClose,
  handleConfirm,
  assetID,
  collectionID,
  maxFile
}: {
  open: boolean;
  handleClose: () => void;
  handleConfirm: any;
  assetID: number;
  collectionID: number;
  maxFile: number;
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  const image = useRecoilValue(imagesAtom);

  const deleteMediaMutation = useDeleteMedia();
  const uploadMediaMutation = useUploadMedia();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to change this?
        </Typography>
        <Box>
          <Button onClick={handleClose}>No</Button>
          <Button
          disabled={image < maxFile}
            onClick={() => {
              deleteMediaMutation.mutate({ id: assetID, page: 'collection' });
              image.forEach((img: File) => {
                uploadMediaMutation.mutate({
                  file: img,
                  id: collectionID,
                  page: 'collection'
                }, {
                  onSuccess: handleClose
                });
              });
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AreYouSureModal;
